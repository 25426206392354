export enum Tenure {
  LessThanSixMonths = 'LessThanSixMonths',
  FromSixMonthsToTwoYears = 'FromSixMonthsToTwoYears',
  FromTwoYearsToFiveYears = 'FromTwoYearsToFiveYears',
  MoreThanFiveYears = 'MoreThanFiveYears',
}

export enum GroupBy {
  Departments = 'Departments',
  Roles = 'Roles',
  Locations = 'Locations',
  Tenures = 'Tenures',
}

export enum SortBy {
  PeopleCount = 'ppl',
  Positive = 'positive',
  PositiveDiff = 'positive_diff',
  Negative = 'negative',
  NegativeDiff = 'negative_diff',
}

export enum Predictor {
  Community = 'Community',
  Control = 'Control',
  Workload = 'Workload',
  Values = 'Values',
  Reward = 'Reward',
  Fairness = 'Fairness',
  Confession = 'Confession',
}

export enum Plan {
  Trial = 'Trial',
  Free = 'Free',
  Pro = 'Pro',
}

export enum MainPredictorType {
  Turnover = 'turnover',
  Enps = 'enps',
}
