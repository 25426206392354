export const ROW_ID = {
  OVERALL: 'overall',
  FILTERED: 'filtered',
};

export const COLUMN_ID = {
  HIERARCHY: 'hierarchy',
  NAME: 'name',
  COMPARISON: 'comparison',
  PEOPLE_COUNT: 'ppl',
  ENPS: 'enps',
  ENPS_DIFF: 'enps-diff',
  TURNOVER: 'turnover',
  TURNOVER_DIFF: 'turnover-diff',
  COMMUNITY_POSITIVE: 'community-positive',
  COMMUNITY_POSITIVE_DIFF: 'community-positive-diff',
  COMMUNITY_NEGATIVE: 'community-negative',
  COMMUNITY_NEGATIVE_DIFF: 'community-negative-diff',
  CONTROL_POSITIVE: 'control-positive',
  CONTROL_POSITIVE_DIFF: 'control-positive-diff',
  CONTROL_NEGATIVE: 'control-negative',
  CONTROL_NEGATIVE_DIFF: 'control-negative-diff',
  WORKLOAD_POSITIVE: 'workload-positive',
  WORKLOAD_POSITIVE_DIFF: 'workload-positive-diff',
  WORKLOAD_NEGATIVE: 'workload-negative',
  WORKLOAD_NEGATIVE_DIFF: 'workload-negative-diff',
  VALUES_POSITIVE: 'values-positive',
  VALUES_POSITIVE_DIFF: 'values-positive-diff',
  VALUES_NEGATIVE: 'values-negative',
  VALUES_NEGATIVE_DIFF: 'values-negative-diff',
  REWARD_POSITIVE: 'reward-positive',
  REWARD_POSITIVE_DIFF: 'reward-positive-diff',
  REWARD_NEGATIVE: 'reward-negative',
  REWARD_NEGATIVE_DIFF: 'reward-negative-diff',
  FAIRNESS_POSITIVE: 'fairness-positive',
  FAIRNESS_POSITIVE_DIFF: 'fairness-positive-diff',
  FAIRNESS_NEGATIVE: 'fairness-negative',
  FAIRNESS_NEGATIVE_DIFF: 'fairness-negative-diff',
  CONFESSION_POSITIVE: 'confession-positive',
  CONFESSION_POSITIVE_DIFF: 'confession-positive-diff',
  CONFESSION_NEGATIVE: 'confession-negative',
  CONFESSION_NEGATIVE_DIFF: 'confession-negative-diff',
};
