import React from 'react';
import { ColumnDef, Row, TableState } from '@tanstack/react-table';

import { ITableDataRow } from './Overview.types';
import {
  TurnoverHeader,
  EnpsHeader,
  NameCell,
  NameHeader,
  NegativeCell,
  NegativeDiffCell,
  NegativeDiffHeader,
  PositiveCell,
  PositiveDiffCell,
  PositiveDiffHeader,
  PplCell,
  PplHeader,
  PredictorNegativeHeader,
  PredictorPositiveHeader,
  HierarchyCell,
  ComparisonCell,
} from './OverviewTable.cells';
import { COLUMN_ID } from './OverviewTable.constants';

export default function useColumnDefs(state: Partial<TableState>): ColumnDef<ITableDataRow>[] {
  const sortingFn = React.useCallback(
    (a: Row<ITableDataRow>, b: Row<ITableDataRow>, column: string): number => {
      const { desc } = state.sorting!.at(0)!;

      const aValue = a.getValue<number | undefined>(column);
      const bValue = b.getValue<number | undefined>(column);

      if (aValue === undefined && bValue === undefined) {
        return 0;
      }

      if (aValue === undefined) {
        return desc ? -1 : 1;
      }

      if (bValue === undefined) {
        return desc ? 1 : -1;
      }

      return aValue - bValue;
    },
    [state],
  );

  return React.useMemo<ColumnDef<ITableDataRow>[]>(
    () => [
      {
        id: COLUMN_ID.HIERARCHY,
        cell: HierarchyCell,
      },
      {
        id: COLUMN_ID.NAME,
        accessorFn: (row) => row.groupName,
        cell: NameCell,
        header: NameHeader,
      },
      {
        id: COLUMN_ID.COMPARISON,
        cell: ComparisonCell,
      },
      {
        id: COLUMN_ID.PEOPLE_COUNT,
        accessorFn: (row) => row.ppl,
        cell: PplCell,
        header: PplHeader,
      },
      {
        id: COLUMN_ID.ENPS,
        accessorFn: (row) => row.statistics?.enps.value,
        cell: PositiveCell,
        header: EnpsHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.ENPS_DIFF,
        accessorFn: (row) => row.statistics?.enps.difference,
        cell: PositiveDiffCell,
        header: PositiveDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.TURNOVER,
        accessorFn: (row) => row.statistics?.turnover?.value,
        cell: NegativeCell,
        header: TurnoverHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.TURNOVER_DIFF,
        accessorFn: (row) => row.statistics?.turnover?.difference,
        cell: NegativeDiffCell,
        header: NegativeDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.COMMUNITY_POSITIVE,
        accessorFn: (row) => row.statistics?.community?.positive.value,
        cell: PositiveCell,
        header: PredictorPositiveHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.COMMUNITY_POSITIVE_DIFF,
        accessorFn: (row) => row.statistics?.community?.positive.difference,
        cell: PositiveDiffCell,
        header: PositiveDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.COMMUNITY_NEGATIVE,
        accessorFn: (row) => row.statistics?.community?.negative.value,
        cell: NegativeCell,
        header: PredictorNegativeHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.COMMUNITY_NEGATIVE_DIFF,
        accessorFn: (row) => row.statistics?.community?.negative.difference,
        cell: NegativeDiffCell,
        header: NegativeDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.CONTROL_POSITIVE,
        accessorFn: (row) => row.statistics?.control?.positive.value,
        cell: PositiveCell,
        header: PredictorPositiveHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.CONTROL_POSITIVE_DIFF,
        accessorFn: (row) => row.statistics?.control?.positive.difference,
        cell: PositiveDiffCell,
        header: PositiveDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.CONTROL_NEGATIVE,
        accessorFn: (row) => row.statistics?.control?.negative.value,
        cell: NegativeCell,
        header: PredictorNegativeHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.CONTROL_NEGATIVE_DIFF,
        accessorFn: (row) => row.statistics?.control?.negative.difference,
        cell: NegativeDiffCell,
        header: NegativeDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.WORKLOAD_POSITIVE,
        accessorFn: (row) => row.statistics?.workload?.positive.value,
        cell: PositiveCell,
        header: PredictorPositiveHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.WORKLOAD_POSITIVE_DIFF,
        accessorFn: (row) => row.statistics?.workload?.positive.difference,
        cell: PositiveDiffCell,
        header: PositiveDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.WORKLOAD_NEGATIVE,
        accessorFn: (row) => row.statistics?.workload?.negative.value,
        cell: NegativeCell,
        header: PredictorNegativeHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.WORKLOAD_NEGATIVE_DIFF,
        accessorFn: (row) => row.statistics?.workload?.negative.difference,
        cell: NegativeDiffCell,
        header: NegativeDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.VALUES_POSITIVE,
        accessorFn: (row) => row.statistics?.values?.positive.value,
        cell: PositiveCell,
        header: PredictorPositiveHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.VALUES_POSITIVE_DIFF,
        accessorFn: (row) => row.statistics?.values?.positive.difference,
        cell: PositiveDiffCell,
        header: PositiveDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.VALUES_NEGATIVE,
        accessorFn: (row) => row.statistics?.values?.negative.value,
        cell: NegativeCell,
        header: PredictorNegativeHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.VALUES_NEGATIVE_DIFF,
        accessorFn: (row) => row.statistics?.values?.negative.difference,
        cell: NegativeDiffCell,
        header: NegativeDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.REWARD_POSITIVE,
        accessorFn: (row) => row.statistics?.reward?.positive.value,
        cell: PositiveCell,
        header: PredictorPositiveHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.REWARD_POSITIVE_DIFF,
        accessorFn: (row) => row.statistics?.reward?.positive.difference,
        cell: PositiveDiffCell,
        header: PositiveDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.REWARD_NEGATIVE,
        accessorFn: (row) => row.statistics?.reward?.negative.value,
        cell: NegativeCell,
        header: PredictorNegativeHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.REWARD_NEGATIVE_DIFF,
        accessorFn: (row) => row.statistics?.reward?.negative.difference,
        cell: NegativeDiffCell,
        header: NegativeDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.FAIRNESS_POSITIVE,
        accessorFn: (row) => row.statistics?.fairness?.positive.value,
        cell: PositiveCell,
        header: PredictorPositiveHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.FAIRNESS_POSITIVE_DIFF,
        accessorFn: (row) => row.statistics?.fairness?.positive.difference,
        cell: PositiveDiffCell,
        header: PositiveDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.FAIRNESS_NEGATIVE,
        accessorFn: (row) => row.statistics?.fairness?.negative.value,
        cell: NegativeCell,
        header: PredictorNegativeHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.FAIRNESS_NEGATIVE_DIFF,
        accessorFn: (row) => row.statistics?.fairness?.negative.difference,
        cell: NegativeDiffCell,
        header: NegativeDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.CONFESSION_POSITIVE,
        accessorFn: (row) => row.statistics?.confession?.positive.value,
        cell: PositiveCell,
        header: PredictorPositiveHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.CONFESSION_POSITIVE_DIFF,
        accessorFn: (row) => row.statistics?.confession?.positive.difference,
        cell: PositiveDiffCell,
        header: PositiveDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.CONFESSION_NEGATIVE,
        accessorFn: (row) => row.statistics?.confession?.negative.value,
        cell: NegativeCell,
        header: PredictorNegativeHeader,
        sortingFn,
        sortUndefined: false,
      },
      {
        id: COLUMN_ID.CONFESSION_NEGATIVE_DIFF,
        accessorFn: (row) => row.statistics?.confession?.negative.difference,
        cell: NegativeDiffCell,
        header: NegativeDiffHeader,
        sortingFn,
        sortUndefined: false,
      },
    ],
    [sortingFn],
  );
}
