import React, { useCallback, useMemo } from 'react';
import { Box, Divider, Flex, SimpleGrid, Space, useMantineTheme } from '@mantine/core';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
  TableState,
  ExpandedState,
  createRow,
  Cell,
  OnChangeFn,
  Updater,
  SortingState,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { BooleanParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useMediaQuery } from '@mantine/hooks';

import { GroupBy, Predictor, SortBy } from '../../statistics/types/enums';
import { IOverallOverview } from '../../statistics/types/overview';
import groupByColumns from '../../helpers/groupByColumns';
import { useGroupBy } from '../../globalStates/GroupBy';
import { usePredictor } from '../../globalStates/Predictor';
import { ELEMENT_ID } from '../../constants';

import useStyles from './OverviewTable.style';
import { ITableDataRow } from './Overview.types';
import { COLUMN_ID, ROW_ID } from './OverviewTable.constants';
import useColumnDefs from './OverviewTable.column-defs';
import BodyRow from './BodyRow/BodyRow';

export default function OverviewTable({
  isFiltered,
  filtered,
  overall,
  showDiff,
  rows,
}: {
  isFiltered: boolean;
  filtered: IOverallOverview;
  overall: IOverallOverview;
  rows: ITableDataRow[];
  showDiff: boolean;
}) {
  const showHierarchy = rows.some((r) => r.subRows !== undefined && r.subRows.length > 0);
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const { classes } = useStyles({
    withDiffs: showDiff,
    withHierarchy: showHierarchy,
    mobile,
  });
  const { t } = useTranslation();

  const [groupBy] = useGroupBy();
  const [{ predictor }] = usePredictor();
  const [{ sortBy, sortDesc, expandedDepartments, expandedRoles }, setQuery] = useQueryParams({
    sortBy: StringParam,
    sortDesc: withDefault(BooleanParam, true),
    expandedDepartments: StringParam,
    expandedRoles: StringParam,
  });

  const expandedState = useMemo<ExpandedState>(() => {
    function fromString(str: string) {
      return str
        .split(',')
        .filter((x) => x !== '')
        .reduce<Record<string, boolean>>((map, curr) => ({ ...map, [curr]: true }), {});
    }

    if (groupBy === GroupBy.Departments) {
      return expandedDepartments ? fromString(expandedDepartments) : {};
    }

    if (groupBy === GroupBy.Roles) {
      return expandedRoles ? fromString(expandedRoles) : {};
    }

    return {};
  }, [expandedDepartments, expandedRoles, groupBy]);

  const sortingState = React.useMemo<SortingState>(() => {
    function getSortingColumnId() {
      switch (sortBy) {
        case SortBy.PeopleCount:
          return COLUMN_ID.PEOPLE_COUNT;

        case SortBy.Positive:
          if (!predictor) {
            return COLUMN_ID.ENPS;
          }

          return {
            [Predictor.Community]: COLUMN_ID.COMMUNITY_POSITIVE,
            [Predictor.Control]: COLUMN_ID.CONTROL_POSITIVE,
            [Predictor.Workload]: COLUMN_ID.WORKLOAD_POSITIVE,
            [Predictor.Values]: COLUMN_ID.VALUES_POSITIVE,
            [Predictor.Reward]: COLUMN_ID.REWARD_POSITIVE,
            [Predictor.Fairness]: COLUMN_ID.FAIRNESS_POSITIVE,
            [Predictor.Confession]: COLUMN_ID.CONFESSION_POSITIVE,
          }[predictor];

        case SortBy.PositiveDiff:
          if (!predictor) {
            return COLUMN_ID.ENPS_DIFF;
          }

          return {
            [Predictor.Community]: COLUMN_ID.COMMUNITY_POSITIVE_DIFF,
            [Predictor.Control]: COLUMN_ID.CONTROL_POSITIVE_DIFF,
            [Predictor.Workload]: COLUMN_ID.WORKLOAD_POSITIVE_DIFF,
            [Predictor.Values]: COLUMN_ID.VALUES_POSITIVE_DIFF,
            [Predictor.Reward]: COLUMN_ID.REWARD_POSITIVE_DIFF,
            [Predictor.Fairness]: COLUMN_ID.FAIRNESS_POSITIVE_DIFF,
            [Predictor.Confession]: COLUMN_ID.CONFESSION_POSITIVE_DIFF,
          }[predictor];

        case SortBy.Negative:
          if (!predictor) {
            return COLUMN_ID.TURNOVER;
          }

          return {
            [Predictor.Community]: COLUMN_ID.COMMUNITY_NEGATIVE,
            [Predictor.Control]: COLUMN_ID.CONTROL_NEGATIVE,
            [Predictor.Workload]: COLUMN_ID.WORKLOAD_NEGATIVE,
            [Predictor.Values]: COLUMN_ID.VALUES_NEGATIVE,
            [Predictor.Reward]: COLUMN_ID.REWARD_NEGATIVE,
            [Predictor.Fairness]: COLUMN_ID.FAIRNESS_NEGATIVE,
            [Predictor.Confession]: COLUMN_ID.CONFESSION_NEGATIVE,
          }[predictor];

        case SortBy.NegativeDiff:
          if (!predictor) {
            return COLUMN_ID.TURNOVER_DIFF;
          }

          return {
            [Predictor.Community]: COLUMN_ID.COMMUNITY_NEGATIVE_DIFF,
            [Predictor.Control]: COLUMN_ID.CONTROL_NEGATIVE_DIFF,
            [Predictor.Workload]: COLUMN_ID.WORKLOAD_NEGATIVE_DIFF,
            [Predictor.Values]: COLUMN_ID.VALUES_NEGATIVE_DIFF,
            [Predictor.Reward]: COLUMN_ID.REWARD_NEGATIVE_DIFF,
            [Predictor.Fairness]: COLUMN_ID.FAIRNESS_NEGATIVE_DIFF,
            [Predictor.Confession]: COLUMN_ID.CONFESSION_NEGATIVE_DIFF,
          }[predictor];

        default:
          return COLUMN_ID.PEOPLE_COUNT;
      }
    }

    return [
      {
        id: getSortingColumnId(),
        desc: sortDesc,
      },
    ];
  }, [predictor, sortBy, sortDesc]);

  const onExpandedChange: OnChangeFn<ExpandedState> = useCallback(
    (updater: Updater<ExpandedState>) => {
      function toString(exp: Record<string, boolean>) {
        const keys = Object.keys(exp).filter((x) => !!exp[x]);
        return keys.length ? keys.join(',') : null;
      }

      const newState = (typeof updater === 'function' ? updater(expandedState) : updater) as Record<string, boolean>;

      if (groupBy === GroupBy.Departments) {
        setQuery({
          expandedDepartments: toString(newState),
        });
      }

      if (groupBy === GroupBy.Roles) {
        setQuery({
          expandedRoles: toString(newState),
        });
      }
    },
    [expandedState, groupBy, setQuery],
  );

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (updater: Updater<SortingState>) => {
      const newState = typeof updater === 'function' ? updater(sortingState) : updater;
      const sorting = newState.at(0);

      if (!sorting) {
        return;
      }

      function getNewSortBy(columnId: string) {
        switch (columnId) {
          case COLUMN_ID.PEOPLE_COUNT:
            return SortBy.PeopleCount;
          case COLUMN_ID.ENPS:
          case COLUMN_ID.COMMUNITY_POSITIVE:
          case COLUMN_ID.CONTROL_POSITIVE:
          case COLUMN_ID.WORKLOAD_POSITIVE:
          case COLUMN_ID.VALUES_POSITIVE:
          case COLUMN_ID.REWARD_POSITIVE:
          case COLUMN_ID.FAIRNESS_POSITIVE:
          case COLUMN_ID.CONFESSION_POSITIVE:
            return SortBy.Positive;
          case COLUMN_ID.ENPS_DIFF:
          case COLUMN_ID.COMMUNITY_POSITIVE_DIFF:
          case COLUMN_ID.CONTROL_POSITIVE_DIFF:
          case COLUMN_ID.WORKLOAD_POSITIVE_DIFF:
          case COLUMN_ID.VALUES_POSITIVE_DIFF:
          case COLUMN_ID.REWARD_POSITIVE_DIFF:
          case COLUMN_ID.FAIRNESS_POSITIVE_DIFF:
          case COLUMN_ID.CONFESSION_POSITIVE_DIFF:
            return SortBy.PositiveDiff;
          case COLUMN_ID.TURNOVER:
          case COLUMN_ID.COMMUNITY_NEGATIVE:
          case COLUMN_ID.CONTROL_NEGATIVE:
          case COLUMN_ID.WORKLOAD_NEGATIVE:
          case COLUMN_ID.VALUES_NEGATIVE:
          case COLUMN_ID.REWARD_NEGATIVE:
          case COLUMN_ID.FAIRNESS_NEGATIVE:
          case COLUMN_ID.CONFESSION_NEGATIVE:
            return SortBy.Negative;
          case COLUMN_ID.TURNOVER_DIFF:
          case COLUMN_ID.COMMUNITY_NEGATIVE_DIFF:
          case COLUMN_ID.CONTROL_NEGATIVE_DIFF:
          case COLUMN_ID.WORKLOAD_NEGATIVE_DIFF:
          case COLUMN_ID.VALUES_NEGATIVE_DIFF:
          case COLUMN_ID.REWARD_NEGATIVE_DIFF:
          case COLUMN_ID.FAIRNESS_NEGATIVE_DIFF:
          case COLUMN_ID.CONFESSION_NEGATIVE_DIFF:
            return SortBy.NegativeDiff;
          default:
            return undefined;
        }
      }

      const newSortBy = getNewSortBy(sorting.id);
      if (!newSortBy) {
        return;
      }

      setQuery({ sortBy: newSortBy, sortDesc: sorting.desc });
    },
    [sortingState, setQuery],
  );

  const state = React.useMemo<Partial<TableState>>(
    () => ({
      expanded: expandedState,
      sorting: sortingState,
      columnVisibility: {
        [COLUMN_ID.COMPARISON]: !mobile,
        [COLUMN_ID.ENPS]: !predictor,
        [COLUMN_ID.ENPS_DIFF]: !mobile && showDiff && !predictor,
        [COLUMN_ID.TURNOVER]: !predictor,
        [COLUMN_ID.TURNOVER_DIFF]: !mobile && showDiff && !predictor,
        [COLUMN_ID.COMMUNITY_POSITIVE]: predictor === 'Community',
        [COLUMN_ID.COMMUNITY_POSITIVE_DIFF]: !mobile && showDiff && predictor === 'Community',
        [COLUMN_ID.COMMUNITY_NEGATIVE]: predictor === 'Community',
        [COLUMN_ID.COMMUNITY_NEGATIVE_DIFF]: !mobile && showDiff && predictor === 'Community',
        [COLUMN_ID.CONTROL_POSITIVE]: predictor === 'Control',
        [COLUMN_ID.CONTROL_POSITIVE_DIFF]: !mobile && showDiff && predictor === 'Control',
        [COLUMN_ID.CONTROL_NEGATIVE]: predictor === 'Control',
        [COLUMN_ID.CONTROL_NEGATIVE_DIFF]: !mobile && showDiff && predictor === 'Control',
        [COLUMN_ID.WORKLOAD_POSITIVE]: predictor === 'Workload',
        [COLUMN_ID.WORKLOAD_POSITIVE_DIFF]: !mobile && showDiff && predictor === 'Workload',
        [COLUMN_ID.WORKLOAD_NEGATIVE]: predictor === 'Workload',
        [COLUMN_ID.WORKLOAD_NEGATIVE_DIFF]: !mobile && showDiff && predictor === 'Workload',
        [COLUMN_ID.VALUES_POSITIVE]: predictor === 'Values',
        [COLUMN_ID.VALUES_POSITIVE_DIFF]: !mobile && showDiff && predictor === 'Values',
        [COLUMN_ID.VALUES_NEGATIVE]: predictor === 'Values',
        [COLUMN_ID.VALUES_NEGATIVE_DIFF]: !mobile && showDiff && predictor === 'Values',
        [COLUMN_ID.REWARD_POSITIVE]: predictor === 'Reward',
        [COLUMN_ID.REWARD_POSITIVE_DIFF]: !mobile && showDiff && predictor === 'Reward',
        [COLUMN_ID.REWARD_NEGATIVE]: predictor === 'Reward',
        [COLUMN_ID.REWARD_NEGATIVE_DIFF]: !mobile && showDiff && predictor === 'Reward',
        [COLUMN_ID.FAIRNESS_POSITIVE]: predictor === 'Fairness',
        [COLUMN_ID.FAIRNESS_POSITIVE_DIFF]: !mobile && showDiff && predictor === 'Fairness',
        [COLUMN_ID.FAIRNESS_NEGATIVE]: predictor === 'Fairness',
        [COLUMN_ID.FAIRNESS_NEGATIVE_DIFF]: !mobile && showDiff && predictor === 'Fairness',
        [COLUMN_ID.CONFESSION_POSITIVE]: predictor === 'Confession',
        [COLUMN_ID.CONFESSION_POSITIVE_DIFF]: !mobile && showDiff && predictor === 'Confession',
        [COLUMN_ID.CONFESSION_NEGATIVE]: predictor === 'Confession',
        [COLUMN_ID.CONFESSION_NEGATIVE_DIFF]: !mobile && showDiff && predictor === 'Confession',
      },
    }),
    [predictor, mobile, showDiff, expandedState, sortingState],
  );

  const columnDefs = useColumnDefs(state);
  const cellsCount = showDiff ? 8 : 6;

  const table = useReactTable<ITableDataRow>({
    data: rows,
    columns: columnDefs,
    getRowId: (row) => row.id,
    onExpandedChange,
    onSortingChange,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state,
  });

  const overallRow: ITableDataRow = {
    ...overall,
    id: ROW_ID.OVERALL,
    groupName: t('OverviewTable.Overall'),
  };

  const filteredRow: ITableDataRow = {
    ...filtered,
    id: ROW_ID.FILTERED,
    groupName: t('OverviewTable.Filtered'),
  };

  const getElementByCell = useCallback(
    (cell: Cell<ITableDataRow, unknown>) => {
      if (cell.column.id === COLUMN_ID.NAME) {
        return (
          <Flex key={cell.id} align="center" justify="start" h={!mobile ? 30 : 35} className={classes.withoutMargin}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Flex>
        );
      }
      if (cell.column.id === COLUMN_ID.HIERARCHY) {
        return (
          <Flex key={cell.id} align="center" justify="end" h={!mobile ? 30 : 35} className={classes.withoutMargin}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Flex>
        );
      }
      if (cell.column.id === COLUMN_ID.COMPARISON) {
        return (
          <Flex key={cell.id} align="center" justify="start" h={!mobile ? 30 : 35} className={classes.withoutMargin}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Flex>
        );
      }

      return (
        <Flex key={cell.id} align="center" justify="end" h={!mobile ? 30 : 35} className={classes.withoutMargin}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Flex>
      );
    },
    [mobile, classes],
  );

  return (
    <Box className={classes.container}>
      <div className={classes.headerWrapper}>
        <Space h={20} />
        {predictor && <div className={classes.predictor}>{t(`Predictor.${predictor}`)}</div>}
        <div className={classes.sortingFeaturesOverlay} id={ELEMENT_ID.SORTING_FEATURES} />
        <SimpleGrid cols={cellsCount} className={classes.headerGrid} verticalSpacing={0} spacing={0}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <Flex key={header.id} justify="end" align="center" className={classes.withoutMargin}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </Flex>
            )),
          )}
        </SimpleGrid>
        <Space h={10} />
      </div>

      {table.getRowModel().rows.map((row) => (
        <BodyRow key={row.id} groupId={row.id}>
          <SimpleGrid cols={cellsCount} className={classes.grid} verticalSpacing={0} spacing={0}>
            {groupByColumns(row.getVisibleCells()).map((group) => group.cells.map((cell) => getElementByCell(cell)))}
          </SimpleGrid>
        </BodyRow>
      ))}
      <div className={classes.footerWrapper}>
        <Divider mt={4} />
        <SimpleGrid cols={cellsCount} className={classes.grid} verticalSpacing={0} spacing={0}>
          {isFiltered &&
            createRow(table, filteredRow.id, filteredRow, 0, 0, undefined, undefined)
              .getVisibleCells()
              .map((cell) => getElementByCell(cell))}
          {createRow(table, overallRow.id, overallRow, 0, 0, undefined, undefined)
            .getVisibleCells()
            .map((cell) => getElementByCell(cell))}
        </SimpleGrid>
      </div>
    </Box>
  );
}
